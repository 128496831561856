import React, { useMemo, useState } from 'react'

import { clsx } from '@guiker/clsx'
import { padding } from '@guiker/components-core'
import { last } from '@guiker/lodash'

import { makeStyles } from '../../../styles'
import { Flex, P, PSmall, PSmaller } from '../..'
import { RotatingIcon } from '../../Feedback'
import { CellRenderable, isCellRendableSingleOrArray } from './cell-renderable'
import { CollapsibleTableRow } from './CollapsibleTableRow'
import { ColumnConfig } from './column-config'
import { TableProps } from './Table'

type TableRowProps<T extends object> = Pick<
  TableProps<T>,
  'columns' | 'condensed' | 'isRowCollapsible' | 'collapsibleOptions' | 'collapsibleComponent'
> & {
  item: T
  index: number
  onClick: (data: T) => unknown
  withBorder?: boolean
  className?: string
  collapseClassName?: string
  collapsibleFooter?: React.FC<{ item: T }>
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover,
      },
    },
    td: {
      padding: ({ condensed }: { condensed: boolean }) => (condensed ? padding(1, 1) : padding(2, 1)),
      wordWrap: 'break-word',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.grey[10],
      textAlign: ({ textAlign }: { textAlign: React.CSSProperties['textAlign'] }) => textAlign || 'left',
      cursor: ({ hasClickHandler }: { hasClickHandler: boolean }) => (hasClickHandler ? 'pointer' : 'default'),
    },
    collapseCell: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
      minWidth: 20,
    },
  }),
  { name: 'TableRow' },
)

export const BaseTR: React.FC<
  React.PropsWithChildren & {
    onClick?: (event: React.MouseEvent) => unknown
    className?: string
  }
> = ({ children, className, onClick }) => {
  const classes = useStyles({ hasClickHandler: !!onClick })
  return (
    <tr className={clsx(classes.root, className)} onClick={onClick}>
      {children}
    </tr>
  )
}

type BaseTDProps = React.PropsWithChildren & {
  className?: string
  rowSpan?: number
  colSpan?: number
  verticalAlign?: 'top' | 'middle' | 'bottom'
  textAlign?: React.CSSProperties['textAlign']
  hasClickHandler?: boolean
  condensed?: boolean
}

export const BaseTD: React.FC<BaseTDProps> = ({
  children,
  className,
  colSpan,
  rowSpan,
  verticalAlign = 'middle',
  textAlign,
  hasClickHandler,
  condensed,
}) => {
  const classes = useStyles({ textAlign, hasClickHandler, condensed })

  return (
    <td valign={verticalAlign} rowSpan={rowSpan} colSpan={colSpan} className={clsx(classes.td, className)}>
      {children}
    </td>
  )
}

const isRowCollapsibleBoolean = (isCollapsible: unknown): isCollapsible is boolean => {
  return typeof isCollapsible === 'boolean'
}

export const defaultRendered = (
  value: CellRenderable | CellRenderable[],
  renderValueType: 'small' | 'smaller' | 'medium',
  textAlign: React.CSSProperties['textAlign'] = 'left',
) => {
  const primary = Array.isArray(value) ? value[0] : value
  const secondary = Array.isArray(value) ? value[1] : undefined

  const Primary = renderValueType === 'smaller' ? PSmaller : renderValueType === 'small' ? PSmall : P
  const Secondary = renderValueType === 'smaller' ? PSmaller : renderValueType === 'small' ? PSmaller : PSmall

  return (
    <Flex flexDirection='column' gap={0.5}>
      <Primary mb={0} textAlign={textAlign}>
        {primary}
      </Primary>
      {secondary && (
        <Secondary mb={0} color={60} textAlign={textAlign}>
          {secondary}
        </Secondary>
      )}
    </Flex>
  )
}

const TableDataRender = <T extends object>(props: {
  renderValue: ColumnConfig<T>['renderValue']
  headerOptions?: ColumnConfig<T>['headerOptions']
  item: T
  index: number
  condensed: boolean
}) => {
  const { renderValue, item, index, headerOptions, condensed } = props
  const renderedValue = renderValue({ ...item, index })

  const tdContent = useMemo(() => {
    return isCellRendableSingleOrArray(renderedValue)
      ? defaultRendered(renderedValue, condensed ? 'small' : 'medium', headerOptions?.textAlign)
      : renderedValue
  }, [renderedValue])

  return <>{tdContent}</>
}

const TableRow = <T extends object>({
  columns,
  condensed,
  item,
  index,
  isRowCollapsible: isRowCollapsibleProp = false,
  collapsibleOptions,
  collapsibleComponent,
  onClick,
  className,
  collapseClassName,
}: TableRowProps<T>) => {
  const [isOpen, setIsOpen] = useState(collapsibleOptions?.isOpen ?? false)

  const onClickHandler = (event: React.MouseEvent) => {
    if (onClick) {
      event?.preventDefault()
      onClick(item)
    }
  }

  const isRowCollapsible = isRowCollapsibleBoolean(isRowCollapsibleProp)
    ? isRowCollapsibleProp
    : isRowCollapsibleProp(item)

  return (
    <>
      <BaseTR onClick={onClickHandler}>
        {columns.map(({ name, renderValue, verticalAlign = 'middle', headerOptions, colSpan }) => {
          if (name === 'collapseRow') return

          return (
            <BaseTD
              verticalAlign={verticalAlign}
              key={name}
              className={className}
              colSpan={colSpan}
              textAlign={headerOptions?.textAlign}
              hasClickHandler={!!onClick}
              condensed={condensed}
            >
              <TableDataRender
                renderValue={renderValue}
                headerOptions={headerOptions}
                item={item}
                index={index}
                condensed={condensed}
              />
            </BaseTD>
          )
        })}
        {!!isRowCollapsibleProp && (
          <BaseTD verticalAlign={last(columns)?.verticalAlign} className={className}>
            {isRowCollapsible && (
              <Flex fullWidth height={'100%'} onClick={() => setIsOpen(!isOpen)} justifyContent='center'>
                <RotatingIcon isRotated={isOpen} color={50} size='smaller' />
              </Flex>
            )}
          </BaseTD>
        )}
      </BaseTR>
      {(isRowCollapsible || collapsibleComponent) && (
        <CollapsibleTableRow
          condensed={condensed}
          columns={columns}
          item={item}
          open={isOpen}
          collapsibleComponent={collapsibleComponent}
          className={collapseClassName}
        />
      )}
    </>
  )
}

export { TableRow }
